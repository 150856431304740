import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { Accordion, Spinner } from '../../components';
import Select from 'react-select';
import { all } from '../../helpers/actions/operation';
import { getProPrice } from '../Projects/Devis/Prices';
import LazyLoad from 'react-lazyload';

class CatalogModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      groups: [],
      group: null,
      pieces: [],
      piece: null,
      search: '',
      catalog: [],
      operations: [],
      selected: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    let { typeProject, selected, dispatch } = this.props;
    let type = typeProject === 'compound-son' ? 'compound' : typeProject;

    dispatch(all({ limit: 1000, type }))
      .then(({ data }) => {
        let catalog = data;

        if (selected.length && catalog.length) {
          selected.forEach(s => {
            const index = catalog.findIndex(
              c => c.name === s.name && c.group === s.group
            );

            if (index >= 0) {
              catalog[index].pieces = catalog[index].pieces.filter(
                x => x !== s.piece
              );
              if (catalog[index].pieces.length === 0) {
                catalog.splice(index, 1);
              }
            }
          });
        }

        let groups = this.getSurfaceFilter(catalog);
        let pieces = this.getPieceFilter(catalog);

        setTimeout(() => {
          this.setState({
            groups,
            pieces,
            catalog: catalog,
            operations: catalog,
            piece: this.props.filters.piece,
            loading: false
          });
        }, 1200);
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  cleanModal() {
    this.setState({
      group: null,
      piece: null,
      search: '',
      selected: []
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  getSurfaceFilter(operations) {
    let surfaces = [];
    operations.forEach(op => {
      if (surfaces.findIndex(s => s.label === op.group) === -1)
        surfaces.push({ label: op.group, value: op.group });
    });
    return surfaces;
  }

  getPieceFilter(operations) {
    let pieces = [];
    operations.forEach(op => {
      op.pieces.forEach(piece => {
        if (pieces.findIndex(p => p.label === piece) === -1)
          pieces.push({ label: piece, value: piece });
      });
    });
    pieces.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    return pieces;
  }

  onChangeSelect(event, operation, piece) {
    let { selected } = this.state,
      { provider } = this.props;

    if (event.target.checked) {
      let proPrice = getProPrice(provider, operation.unit_price);

      selected.push({
        name: operation.name,
        unit: operation.unit,
        description: operation.description,
        group: operation.group,
        piece: piece,
        unit_price: operation.unit_price,
        pro_price: proPrice,
        amount: 1,
        tva: provider && provider.tva ? provider.tva : 10,
        materials: operation.materials ? operation.materials : []
      });
    } else {
      const index = selected.findIndex(
        s =>
          s.name === operation.name &&
          s.group === operation.group &&
          s.piece === operation.piece
      );
      selected.splice(index, 1);
    }

    this.setState({ selected });
  }

  changeFilter(field, value) {
    this.setState({ [field]: value });
  }

  onSaveOperations() {
    let { selected } = this.state;
    this.cleanModal();
    this.props.onConfirm(selected);
  }

  render() {
    let {
      operations,
      selected,
      groups,
      group,
      pieces,
      piece,
      search,
      loading
    } = this.state;

    let filterOperation = piece
      ? operations.filter(t => t.pieces.includes(piece))
      : operations;

    filterOperation = group
      ? filterOperation.filter(t => t.group === group)
      : filterOperation;

    filterOperation = search
      ? filterOperation.filter(t =>
          t.name.toUpperCase().includes(search.toUpperCase())
        )
      : filterOperation;

    return (
      <Modal
        size="lg"
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>Select operation from catalog</Trans>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div className={'p-4 m-4'}>
              <Spinner />
            </div>
          ) : (
            <>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup className={'has-label'}>
                    <Label>
                      <Trans>Surface</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={groups}
                      value={group}
                      onChange={event =>
                        this.changeFilter('group', event ? event.value : null)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={'has-label'}>
                    <Label>
                      <Trans>Pieces</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={pieces}
                      value={piece}
                      onChange={event =>
                        this.changeFilter('piece', event ? event.value : null)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup className={'has-label'}>
                    <Label>
                      <Trans>Search</Trans>
                    </Label>
                    <Input
                      type="text"
                      value={search}
                      onChange={event =>
                        this.changeFilter('search', event.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                  <Button
                    color={'info'}
                    onClick={() => this.onSaveOperations()}
                  >
                    <Trans>Add</Trans> ({selected.length})
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12} sm={12} md={12} lg={12}>
                  {filterOperation.length ? (
                    <LazyLoad once>
                      <Accordion
                        plain
                        components={filterOperation.map(operation => {
                          return {
                            title: operation.name,
                            text: (
                              <div>
                                {operation.pieces.sort().map((p, pIndex) => {
                                  if (!piece || (piece && piece === p)) {
                                    return (
                                      <Col xs={12} key={pIndex}>
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              type="checkbox"
                                              checked={
                                                selected.findIndex(
                                                  s =>
                                                    s.name === operation.name &&
                                                    s.group ===
                                                      operation.group &&
                                                    s.piece === p
                                                ) > -1
                                              }
                                              onChange={event =>
                                                this.onChangeSelect(
                                                  event,
                                                  operation,
                                                  p
                                                )
                                              }
                                            />
                                            <span className="form-check-sign" />
                                            {p}
                                          </Label>
                                        </FormGroup>
                                      </Col>
                                    );
                                  }
                                  return '';
                                })}
                              </div>
                            )
                          };
                        })}
                      />
                    </LazyLoad>
                  ) : (
                    <p className={'text-not-found text-center'}>
                      <Trans>No operations found</Trans>
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                  <Button
                    color={'info'}
                    onClick={() => this.onSaveOperations()}
                  >
                    <Trans>Add</Trans>
                    {` (${selected.length})`}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(CatalogModal));
